import styled from 'styled-components'

const Button = styled.button`
  font-family: inherit;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 1rem;
  background-color: ${props => props.disabled ? '#8c9194' : '#5b2f91'};
  color: #ffffff;
  border: 0;
  border-radius: 8px;
  outline: none;
  &:hover, &:focus {
    background-color: ${props => props.disabled ? '#8c9194' : '#6b449c'};
  }
  &:active {
    background-color: ${props => props.disabled ? '#8c9194' : '#7c59a7'};
  }
`

export default Button
