// The state managed by this reducer will show up in the store as state.<ComponentName>
import {
  LOAD_APPOINTMENTS_REQUEST, LOAD_APPOINTMENTS_SUCCESS, LOAD_APPOINTMENTS_ERROR,
  CHANGE_VISITOR_NAME,
  UPDATE_VISITOR_NAME_REQUEST, UPDATE_VISITOR_NAME_SUCCESS, UPDATE_VISITOR_NAME_ERROR,
  LOAD_ROBOTS_SUCCESS
} from './actions'

const initialState = {
  isLoading: false,
  appointments: [],
  error: '',
  appointmentIndex: 0,
  robotType: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_APPOINTMENTS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.appointments,
        isLoading: false,
        error: ''
      }
    case LOAD_APPOINTMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Could not load data. Please try again later.'
      }

    case CHANGE_VISITOR_NAME:
      return {
        ...state,
        appointments: state.appointments.map((a, idx) => {
          if (a._id === action.appointmentId) {
            // get the firstname and surname fields and remove any leading spaces
            // we are not removing trailing spaces yet because otherwise there is no way
            // users can type a space as it will be removed directly
            const firstname = action.field === 'firstname' ? action.value.trimStart() : a.visitor.firstname
            const surname = action.field === 'surname' ? action.value.trimStart() : a.visitor.surname
            return {
              ...a,
              visitor: {
                ...a.visitor,
                firstname,
                surname,
                // we also update the full name. note that in this case the trailing
                // spaces may also be removed.
                name: `${firstname.trim()} ${surname.trim()}`
              }
            }
          }

          return a
        })
      }

    case UPDATE_VISITOR_NAME_REQUEST:
      return {
        ...state,
        error: ''
      }

    case UPDATE_VISITOR_NAME_SUCCESS:
      return {
        ...state,
        appointmentIndex: state.appointmentIndex + 1,
        error: ''
      }

    case UPDATE_VISITOR_NAME_ERROR:
      return {
        ...state,
        error: 'Could not save the visitor name. Please try again later.'
      }

    case LOAD_ROBOTS_SUCCESS: {
      const supportedRobotTypes = ['TEMI', 'PEPPER', 'GREETINGBOT']
      const robotTypes = action.robots
        // we only need to know the robottype (and color in near future I suppose)
        .map(r => r.robotType)
        // remove duplicates
        .filter((r, index, arr) => arr.findIndex(a => a === r) === index)
        // make sure we have an image for the robot
        .filter(r => supportedRobotTypes.includes(r))

      return {
        ...state,
        robotType: robotTypes.find(robot => robot === 'PEPPER') || robotTypes.find(robot => robot === 'TEMI' || robotTypes.find(robot => robot === 'GREETINGBOT')) || 'PEPPER'
      }
    }

    default:
      return state
  }
}

export { initialState, reducer }
