// Here we implement a store for shared state (similar to redux store)
// Also we implement a sagas-like middleware to execute non-blocking api calls
// based on:
// https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/
import React, { createContext, useReducer } from 'react'
import { reducer, initialState } from './reducer'
import { applyMiddleware } from './middleware'

const store = createContext()
const { Provider } = store

const StoreProvider = ({ children }) => {
  // get the reducer
  const [state, originalDispatch] = useReducer(reducer, initialState)

  // add a middleware to the dispatch function which calls apis if needed
  // note: this is similar to what sagas do.
  const dispatch = applyMiddleware(originalDispatch)

  // the provider returns the state and gives access to the dispatch function
  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>)
}

export { store, StoreProvider }
