import React from 'react'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  font-style: italic;
  max-width: 750px;
  margin: 60px 40px;
  animation: ${fadeIn} 2s ease-in;
`

// In order to trigger the animation each time, we set the key property
// so that it appears a new element
const RobotSpeech = ({ text }) => (<Wrapper key={'random' + text}>{text}</Wrapper>)

export default RobotSpeech
