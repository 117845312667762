import styled from 'styled-components'

const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 1.5rem;
  font-family: inherit;
  border-style: none none solid none;
  border-width: 1px;
  border-color: #dde4e9;
  outline-style: none;
  :focus {
    border-color: #5b2f91;
  }
`

export default Input
