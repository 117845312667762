import React, { useEffect, useContext } from 'react'

import Background from '../Background'
import RobotSpeech from '../RobotSpeech'
import Dialog from '../Dialog'
import RobotImage from '../RobotImage'
import Confetti from '../Confetti'

import { store } from '../../store/store'
import { loadAppointments, loadRobots, changeVisitorName, updateVisitorNamesRequest } from './actions'

import speechGenerator from './speech'

const VisitorNames = () => {
  const { state, dispatch } = useContext(store)
  const { appointments, robotType, isLoading, appointmentIndex, error } = state.VisitorNames

  useEffect(() => {
    console.log('VisitorNames useEffect called')
    dispatch(loadAppointments())
    dispatch(loadRobots())
  }, [])

  // the employee name can only be determined if there is at least one appointment
  // TODO: we could improve on this by retrieving it upon login
  const employeeName = (appointments && appointments[appointmentIndex] && appointments[appointmentIndex].employee.firstname) || ''
  const text = speechGenerator(employeeName, appointmentIndex, appointments.length)
  const onChangeName = (id, field, value) => dispatch(changeVisitorName(id, field, value))
  const onSubmit = (id, index) => dispatch(updateVisitorNamesRequest(id, appointments[index].visitor))

  return (
    <Background>
      <RobotSpeech text={error || text}/>
      <Dialog
        appointments={appointments}
        appointmentIndex={appointmentIndex}
        isLoading={isLoading}
        onChange={onChangeName}
        onSubmit={onSubmit}
        error={error}
      />
      { (appointmentIndex === appointments.length && !error) && <Confetti/> }
      <RobotImage robotType={robotType}/>
    </Background>
  )
}

export default VisitorNames
