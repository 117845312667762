import React from 'react'
import styled from 'styled-components'
import Label from '../Label'
import Input from '../Input'

const Wrapper = styled.div`
  margin: 30px 0;
`

const Field = ({ id, label, value, onChange }) => (
  <Wrapper>
    <Label htmlFor={id}>{label}</Label>
    <Input id={id} value={value} onChange={onChange}></Input>
  </Wrapper>
)

export default Field
