import React from 'react'
// as our component is also called Confetti, we need to import the default export
// with an alias.
import ReactConfetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

// The Confetti is extremely CPU intensive.
// That is when we make sure it does not run foreever (by putting recycle=false)
const Confetti = () => {
  const { width, height } = useWindowSize()
  // note: height is one pixel too many. This introduces a vertical scrollbar.
  return (
    <ReactConfetti
      width={width}
      height={height - 1}
      tweenDuration={50000}
      numberOfPieces={window.innerWidth}
      recycle={false}

      // this function draws 'welbo bowties'
      // however it uses WAY too much CPU (over 70% on my desktop PC)
      // drawShape={context => {

      //   context.lineWidth = 1
      //   context.beginPath()
      //   context.moveTo(3,3)
      //   context.lineTo(17+3,6+3)
      //   context.lineTo(18+3,0+3)
      //   context.lineTo(1+3,6+3)
      //   context.lineTo(0+3,0+3)
      //   context.stroke()

      //   context.closePath()

      // }}
    />
  )
}

export default Confetti
