import React from 'react'
import Router from './Router'
import { StoreProvider } from '../store/store'

function App () {
  return (
    <StoreProvider>
      <Router/>
    </StoreProvider>
  )
}

export default App
