import React from 'react'
import styled from 'styled-components'

const Bg = styled.div`
  background: #c0e9df;
  min-height: 100vh;
  width: 100%;
  font-family: 'Nunito', 'sans-serif';
  color: #111112;
`

const ScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow-y: visible;
  @media (max-height: 700px) {
    height: 100%;
  }
`

const Background = ({ children }) => (
  <Bg>
    <ScrollableWrapper>
      {children}
    </ScrollableWrapper>
  </Bg>)

export default Background
