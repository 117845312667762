import io from 'socket.io-client'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'

// environment variables are automatically imported into the build
// **IF** they start with REACT_APP_
console.log('REACT_APP_BACKEND_URL', process.env.REACT_APP_BACKEND_URL)
const socket = io(process.env.REACT_APP_BACKEND_URL, { secure: true, port: 3031, transports: ['websocket', 'polling'], cookie: true })
const client = feathers()

client.configure(socketio(socket))
client.configure(auth({ storageKey: 'visitornamesKey' }))

const api = {}

api.feathers = client

export default api
