import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { store } from '../../store/store'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(store)
  const { authenticated } = state.LoginPage

  return (
    <Route {...rest} render={(props) => (
      authenticated
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
    )}
    />
  )
}

export default PrivateRoute
