export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'

export const REAUTH_REQUEST = 'REAUTH_REQUEST'
export const REAUTH_SUCCESS = 'REAUTH_SUCCESS'
export const REAUTH_ERROR = 'REAUTH_ERROR'

export function authRequest (key) {
  return {
    type: AUTH_REQUEST,
    key
  }
}

export function authSuccess () {
  return {
    type: AUTH_SUCCESS
  }
}

export function authError (error) {
  return {
    type: AUTH_ERROR,
    error
  }
}

export function reAuthRequest () {
  return {
    type: REAUTH_REQUEST
  }
}

export function reAuthSuccess () {
  return {
    type: REAUTH_SUCCESS
  }
}

export function reAuthError () {
  return {
    type: REAUTH_ERROR
  }
}
