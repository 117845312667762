import React from 'react'
import styled from 'styled-components'

// @media (max-width: 500px) {
//     display: none;
//   }
const RobotImg = styled.img`
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
`

const RobotImage = ({ robotType }) => {
  const robotImages = [
    { robotType: 'PEPPER', image: 'pepper.png' },
    { robotType: 'TEMI', image: 'temi-white.png' },
    { robotType: 'GREETINGBOT', image: 'greetingbot.png' }
  ]

  const robot = robotImages.find(ri => ri.robotType === robotType)

  return (robot && <RobotImg src={robot.image}/>) || null
}

export default RobotImage
