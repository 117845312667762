const NO_MEETINGS = [
  'Hi [USERNAME], you are all tidied up. I have proper names for all of your guests!'
]

const FIRST = [
  // eslint-disable-next-line
  'Hi [USERNAME], I see that you have some guests coming over shortly. \
  To welcome them in the best way, I’d need to know their full names. \
  Could you please provide the full names of your guests below?',

  // eslint-disable-next-line
  'Can you help me greet your guests by letting me know their full names please? \
  I\'m currently not too sure how to address all of them'
]

const DONE = [
  'It\'s all done! You\'re the best!',
  'Fantastic! I can greet all guests properly now! Thanks a bunch!',
  'I greatly appreciate your effort in letting me know all the names. Your guests will too!',
  'You just made me really happy. I now have everything I need to know to welcome your guests in the best way possible! Thanks!',
  'You are absolutely amazing! All names are correctly registered!'
]

const LAST = [
  'Great work! Just one more to go!',
  'You are almost there. This is the last one!'
]

const WORK_IN_PROGRESS = [
  'Alright! I have updated the name. Thanks [USERNAME]!',
  'Thanks. Noted.',
  'You are on fire! Thanks for letting me know the full name.',
  'Thank you so much. I really appreciate you taking the time to provide the full names of your guests!',
  'Thanks [USERNAME]!',
  'This is very helpful. Thanks for taking the time! Your guests will appreciate it, I\'m sure!',
  'Much obliged! Thanks!'
]

const messages = {
  NO_MEETINGS,
  FIRST,
  DONE,
  LAST,
  WORK_IN_PROGRESS
}

// generate random number between 0 (inclusive) and max (exclusive)
const random = (max) => Math.floor(Math.random() * max)

// pick random element from array
const pickRandomElement = (array) => array[random(array.length)]

const getCollection = (appointmentIndex, totalNumberOfAppointments) => {
  let collection = 'WORK_IN_PROGRESS'

  if (totalNumberOfAppointments === 0) collection = 'NO_MEETINGS'
  else if (appointmentIndex === 0) collection = 'FIRST'
  else if (appointmentIndex === totalNumberOfAppointments - 1) collection = 'LAST'
  else if (appointmentIndex === totalNumberOfAppointments) collection = 'DONE'

  return collection
}

export { getCollection }

export default (userName, appointmentIndex, totalNumberOfAppointments) => {
  const collection = getCollection(appointmentIndex, totalNumberOfAppointments)

  const text = pickRandomElement(messages[collection])
  return text.replace('[USERNAME]', userName)
}
