import React, { useEffect, useContext } from 'react'
import { useParams, Redirect } from 'react-router-dom'

import Background from '../Background'
import RobotSpeech from '../RobotSpeech'
import RobotImage from '../RobotImage'

import { authRequest } from './actions'
import { store } from '../../store/store'

const LoginPage = () => {
  const { state, dispatch } = useContext(store)
  const { authenticated, loginError } = state.LoginPage

  // Get the Key from the URL
  const { key } = useParams()

  // Attempt to authenticate with the Key from the URL
  useEffect(() => {
    console.log('LoginPage dispatching login request with key', key)
    if (!loginError) dispatch(authRequest(key))
  }, [key, loginError])

  // Redirect after authentication
  if (authenticated) {
    return <Redirect to="/names"/>
  }

  // Redirect on login error
  if (loginError) {
    return <Redirect to="/"/>
  }

  // Draw the login page while trying to login
  return (
    <Background>
      <RobotSpeech text={'Checking your appointment data...'}/>
      <RobotImage/>
    </Background>
  )
}

export default LoginPage
