export const LOAD_APPOINTMENTS_REQUEST = 'LOAD_APPOINTMENTS_REQUEST'
export const LOAD_APPOINTMENTS_SUCCESS = 'LOAD_APPOINTMENTS_SUCCESS'
export const LOAD_APPOINTMENTS_ERROR = 'LOAD_APPOINTMENTS_ERROR'

export const CHANGE_VISITOR_NAME = 'CHANGE_VISITOR_NAME'

export const UPDATE_VISITOR_NAME_REQUEST = 'UPDATE_VISITOR_NAME_REQUEST'
export const UPDATE_VISITOR_NAME_SUCCESS = 'UPDATE_VISITOR_NAME_SUCCESS'
export const UPDATE_VISITOR_NAME_ERROR = 'UPDATE_VISITOR_NAME_ERROR'

export const LOAD_ROBOTS_REQUEST = 'LOAD_ROBOTS_REQUEST'
export const LOAD_ROBOTS_SUCCESS = 'LOAD_ROBOTS_SUCCESS'
export const LOAD_ROBOTS_ERROR = 'LOAD_ROBOTS_ERROR'

export function loadAppointments () {
  return {
    type: LOAD_APPOINTMENTS_REQUEST
  }
}

export function loadAppointmentsSuccess (appointments) {
  return {
    type: LOAD_APPOINTMENTS_SUCCESS,
    appointments
  }
}

export function loadAppointmentsError (error) {
  return {
    type: LOAD_APPOINTMENTS_ERROR,
    error
  }
}

export function changeVisitorName (appointmentId, field, value) {
  return {
    type: CHANGE_VISITOR_NAME,
    appointmentId,
    field,
    value
  }
}

export function updateVisitorNamesRequest (appointmentId, visitor) {
  return {
    type: UPDATE_VISITOR_NAME_REQUEST,
    appointmentId,
    visitor
  }
}

export function updateVisitorNamesSuccess (res) {
  console.log('update success with ', res)
  return {
    type: UPDATE_VISITOR_NAME_SUCCESS
  }
}

export function updateVisitorNamesError (error) {
  console.log('update error', error)
  return {
    type: UPDATE_VISITOR_NAME_ERROR,
    error
  }
}

export function loadRobots () {
  return {
    type: LOAD_ROBOTS_REQUEST
  }
}

export function loadRobotsSuccess (robots) {
  console.log('load success with ', robots)
  return {
    type: LOAD_ROBOTS_SUCCESS,
    robots
  }
}

export function loadRobotsError (error) {
  console.log('load error', error)
  return {
    type: LOAD_ROBOTS_ERROR,
    error
  }
}
