import React from 'react'
import Background from '../Background'
import RobotSpeech from '../RobotSpeech'

const text = 'I am afraid I cannot help you with the URL you provided. \
              Please use the link in your email to open this page.'

const PageDoesNotExist = () => (
  <Background>
    <RobotSpeech text={text}/>
  </Background>
)

export default PageDoesNotExist
