import {
  AUTH_REQUEST, authSuccess, authError,
  REAUTH_REQUEST, reAuthSuccess, reAuthError
} from '../components/LoginPage/actions'

import {
  LOAD_APPOINTMENTS_REQUEST, loadAppointmentsSuccess, loadAppointmentsError,
  UPDATE_VISITOR_NAME_REQUEST, updateVisitorNamesSuccess, updateVisitorNamesError,
  LOAD_ROBOTS_REQUEST, loadRobotsSuccess, loadRobotsError
} from '../components/VisitorNames/actions'

import api from '../services/api'

export const applyMiddleware = dispatch => action => {
  // dispatch the action to the store
  // note: we need to do this so that calls like <action>_REQUEST also end up
  //       being sent to the reducer so that state may get updated.
  dispatch(action)

  // and subsequently handle any API calls that we need to make
  switch (action.type) {
    case AUTH_REQUEST:
      return api.feathers.authenticate({
        strategy: 'tokenlink',
        tokenlink: action.key
      })
        .then(res => dispatch(authSuccess()))
        .catch(err => dispatch(authError(err)))

    case REAUTH_REQUEST:
      return api.feathers.reAuthenticate()
        .then(res => dispatch(reAuthSuccess()))
        .catch(() => dispatch(reAuthError()))

    case LOAD_APPOINTMENTS_REQUEST:
      return api.feathers.service('/appointments')
        .find()
        .then(res => dispatch(loadAppointmentsSuccess(res)))
        .catch(err => dispatch(loadAppointmentsError(err)))

    case UPDATE_VISITOR_NAME_REQUEST: {
      // there may still be leading spaces in the firstname and surname fields
      const visitor = {
        ...action.visitor,
        firstname: action.visitor.firstname.trim(),
        surname: action.visitor.surname.trim(),
        nameIsMissing: false
      }
      // save the data
      return api.feathers.service('/appointments')
        .patch(action.appointmentId, { visitor })
        .then(res => dispatch(updateVisitorNamesSuccess(res)))
        .catch(err => dispatch(updateVisitorNamesError(err)))
    }
    case LOAD_ROBOTS_REQUEST:
      return api.feathers.service('/robots')
        .find()
        .then(res => dispatch(loadRobotsSuccess(res)))
        .catch(err => dispatch(loadRobotsError(err)))

    default:
      // do nothing, as we already dispatched the regular action
  }
}
