import React from 'react'
import styled from 'styled-components'

const StyledError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  font-weight: bold;
  border-radius: 8px;
  border: solid 1px #f5a623;
  padding: 1rem;
  margin: 10px ${props => props.outGrow ? '-20px' : '0px'};
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.15);
`

const ErrorPopup = ({ errorMessage, ...rest }) => {
  return (<StyledError outGrow {...rest}>
    <img src="warning.svg" alt="" style={{ margin: 0, padding: 0 }}/>
    <div style={{ marginLeft: '10px', marginRight: '0px' }}>{errorMessage}</div>
  </StyledError>)
}

export default ErrorPopup
