import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import LoginPage from './LoginPage'
import VisitorNames from './VisitorNames'
import PageDoesNotExist from './PageDoesNotExist'

import { store } from '../store/store'
import { reAuthRequest } from './LoginPage/actions'

const Router = () => {
  const { state, dispatch } = useContext(store)
  const { authenticated, isAttemptingToLogin, isAttemptingToReAuth, loginError, reAuthError } = state.LoginPage

  useEffect(() => {
    // check if the user is already authenticated
    // and, if not, try to re-authenticate based on an existing jwt token
    console.log('Router checking authentication', authenticated)
    if (!authenticated) dispatch(reAuthRequest())
  }, [authenticated])

  // if we just launched the app (or refreshed the browser)
  // nothing has been done yet. be patient...
  if (!isAttemptingToLogin && !isAttemptingToReAuth &&
    !authenticated && !reAuthError && !loginError) { return null }

  // if we are currently trying to login
  // we just wait till this is done.
  if (isAttemptingToLogin || isAttemptingToReAuth) { return null }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/L/:key" component={LoginPage}/>
        <PrivateRoute path="/names" component={VisitorNames}/>
        <Route path="*" component={PageDoesNotExist}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
