import React from 'react'

// TODO: this should be able to handle international timezone.
//       consider implementing day.js https://www.npmjs.com/package/dayjs
/* we can also do it ourselves: https://stackoverflow.com/questions/6939685/get-client-time-zone-from-browser
In plain old javascript (new Date()).getTimezoneOffset()/60 will return the current number
of hours offset from UTC.

It's worth noting a possible "gotcha" in the sign of the getTimezoneOffset() return value
(from MDN docs):

The time-zone offset is the difference, in minutes, between UTC and local time.
Note that this means that the offset is positive if the local timezone is behind
UTC and negative if it is ahead.
For example, for time zone UTC+10:00 (Australian Eastern Standard Time, Vladivostok Time,
  Chamorro Standard Time), -600 will be returned.
*/

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const displayDateString = (isoDateString) => {
  const date = new Date(isoDateString)
  return `${weekDays[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`
}

const displayTimeString = (isoDateString) => {
  const date = new Date(isoDateString)
  // TODO: some customers might prefer AM/PM notation

  // return in 24 hour format
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}

const AppointmentInfoText = ({ datetime, email, ...rest }) => (
  <p {...rest}>
    {displayDateString(datetime)}, you have
    a {displayTimeString(datetime)} meeting
    with <b>{email}</b>
  </p>
)

export default AppointmentInfoText
