import React from 'react'
import styled, { keyframes } from 'styled-components'

import AppointmentInfoText from '../AppointmentInfoText'
import Field from '../Field'
import Button from '../Button'
import Spinner from '../Spinner'
import ErrorPopup from '../ErrorPopup'

import validate from './validate'

const Dlg = styled.div`
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 2px 18px 0 rgba(68, 67, 64, 0.39), 0 0 5px 1px rgba(68, 67, 64, 0.1);
  
  padding: 60px 40px;
  background-color: rgba(255, 255, 255, 0.85);

  margin-bottom: 20px;
  
  width: 400px;

  @media (max-width: 520px) {
    border-radius: 0px;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const FadeInWrapper = styled.div`
  animation: ${fadeIn} 1s ease-in;
`

const Dialog = ({ appointments, appointmentIndex, isLoading, onChange, onSubmit, error }) => {
  const apt = appointments[appointmentIndex]

  const renderLoading = () => <Spinner data-testid='loadingState'/>

  const renderEditing = () => {
    if (!apt) return null
    const inputIsValid = validate(apt.visitor.firstname, apt.visitor.surname)
    return (<FadeInWrapper data-testid='editState' key={'dlg' + appointmentIndex}>
      <AppointmentInfoText data-testid='infoMsg' datetime={apt.meeting.time} email={apt.visitor.email}/>
      {error && <ErrorPopup data-testid='errUpdate' errorMessage={error}/>}
      <Field id="firstname" label="First name" value={apt.visitor.firstname} onChange={e => onChange(apt._id, e.target.id, e.target.value)}/>
      <Field id="surname" label="Last name" value={apt.visitor.surname} onChange={e => onChange(apt._id, e.target.id, e.target.value)}/>
      <div style={{ width: '100%', textAlign: 'right' }}>
        <Button data-testid='submitBtn' disabled={!inputIsValid} onClick={e => onSubmit(apt._id, appointmentIndex)}>
          { (appointments.length - appointmentIndex) > 1 && `Save & Next (${appointments.length - appointmentIndex - 1})` }
          { (appointments.length - appointmentIndex) === 1 && 'Save & Done' }
        </Button>
      </div></FadeInWrapper>
    )
  }

  const renderDone = () => {
    // this function renders the 'check-light.svg' file from Font Awesome.
    // however, to control the color using the 'fill' property, I copied its path here directly.
    return (<div data-testid='doneState' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <svg width="80%" viewBox="0 0 448 512">
        <path fill="#5b2f91" d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/>
      </svg></div>
    )
  }

  return (
    <Dlg>
      { isLoading && renderLoading() }
      { (!isLoading && error && appointments.length === 0) && <ErrorPopup data-testid='errorState' errorMessage={error}/>}
      { (!isLoading && appointmentIndex < appointments.length) && renderEditing() }
      { (!isLoading && !error && appointmentIndex === appointments.length) && renderDone() }
    </Dlg>
  )
}

export default Dialog
