// The state managed by this reducer will show up in the store as state.<ComponentName>
import {
  AUTH_REQUEST, AUTH_SUCCESS, AUTH_ERROR,
  REAUTH_REQUEST, REAUTH_SUCCESS, REAUTH_ERROR
} from './actions'

const initialState = {
  authenticated: false,
  isAttemptingToLogin: false,
  isAttemptingToReAuth: false,
  loginError: null,
  reAuthError: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        isAttemptingToLogin: true,
        isAttemptingToReAuth: false
      }
    case AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isAttemptingToLogin: false,
        isAttemptingToReAuth: false,
        loginError: null,
        reAuthError: null
      }
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        isAttemptingToLogin: false,
        isAttemptingToReAuth: false,
        loginError: 'Error login in',
        reAuthError: null
      }

    case REAUTH_REQUEST:
      return {
        ...state,
        isAttemptingToLogin: false,
        isAttemptingToReAuth: true
      }
    case REAUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isAttemptingToLogin: false,
        isAttemptingToReAuth: false,
        loginError: null,
        reAuthError: null
      }
    case REAUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        isAttemptingToLogin: false,
        isAttemptingToReAuth: false,
        loginError: null,
        reAuthError: 'Error reauth'
      }
    default:
      return state
  }
}

export { initialState, reducer }
