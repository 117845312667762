// The code below mocks require.context so that the tests can run
//
// require.context is a webpack function
// it is not available while running the tests
//
// it is possible to make it available by installing a babel plugin
// 'babel-plugin-require-context-hook'
// However, we need to add it to the .babelrc or babel.config.js which we cannot do
// unless we eject from CRA (create react app).
//
// Therefore implemented this mocked version.
//
// Unfortunately this code gives warnings from webpack.
// "Critical dependency: require function is used in a way in which dependencies cannot be statically extracted"
// "Critical dependency: require function is used in a way in which dependencies cannot be statically extracted"\
// "Critical dependency: the request of a dependency is an expression"
// I could not remove this warning message.
//

// Updating react-scripts to v5 was giving this error:
// Module not found: Error: Can't resolve 'fs' in '/home/sminev/WELBO/visitor-names/src/store'
// the fs and path packages couldn't be resolved. More info here: https://github.com/facebook/create-react-app/issues/11756
// Solved it by adding this in package.json:
//
// "browser": {
//   "fs": false,
//   "path": false,
//   "os": false
// }

// Only add the mock if we are running the tests.
if (typeof require.context === 'undefined') {
  const fs = require('fs')
  const path = require('path')

  require.context = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
    const files = {}

    function readDirectory (directory) {
      fs.readdirSync(directory).forEach((file) => {
        const fullPath = path.resolve(directory, file)

        if (fs.statSync(fullPath).isDirectory()) {
          if (scanSubDirectories) readDirectory(fullPath)

          return
        }

        if (!regularExpression.test(fullPath)) return

        files[fullPath] = true
      })
    }

    readDirectory(path.resolve(__dirname, base))

    function Module (file) {
      return require(file)
    }

    Module.keys = () => Object.keys(files)

    return Module
  }
}
// end of mocked version of require
//

const initialState = {}
const reducers = {}

// get all reduces for each of the components using webpack's require
const req = require.context('../components', true, /\/reducer\.js$/)

req.keys().forEach((key) => {
  const storeName = key.split('/').slice(-2, -1)[0]
  // store the reducer function
  reducers[storeName] = req(key).reducer
  // and the initial state
  initialState[storeName] = req(key).initialState
})

// This is a simple implementation of combineReducers, as also present in redux.
const combineReducers = (reducers) => {
  // the combineReducers simply returns a reducer itself
  return (state = {}, action) => {
    // which calls all reducers and stores the (accumulated) result in nextState
    // the .reduce function then returns this single nextState value
    return Object.keys(reducers)
      .reduce((nextState, key) => {
        // each component has its own key in the state object
        nextState[key] = reducers[key](state[key], action)
        return nextState
      },
      {})
  }
}

const reducer = combineReducers(reducers)

export { initialState, reducer }
